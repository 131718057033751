import React from 'react';
import './App.css';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Progress } from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClock, faSearch, faList, faHome, faArrowLeft, faPencilAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle, faCalendarAlt,  } from '@fortawesome/free-regular-svg-icons';
import 'animate.css';


library.add(faClock, faUserCircle, faCalendarAlt, faSearch, faList, faHome, faArrowLeft, faPencilAlt, faEnvelope, faPhone);

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout/DefaultLayout'));
const Registro = React.lazy(() => import('./containers/Registro/Registro'));
const loading = () => < Progress /> ; 

function App() {
  return (
        <HashRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
                <Route path="/Home" name="Home" render={props => <DefaultLayout {...props}/>} />
                <Route path="/Registro" name="Registro" render={props => <Registro {...props} />} />
              </Switch>
            </React.Suspense>
        </HashRouter>
  );
}

export default App;
 